import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host, MainHost } from "../../Config";

export function EditOrderState({state, changeState, refresh, item, type}) {

    return (
        <ButtonModal title='تغير الحالة' btnText="نعم" state={state} changeState={changeState} btnAction={() => {
            insert(item.id, type);
        }}>
            <h6>هل انت متاكد من تغير حالة الطلبية ؟</h6>
        </ButtonModal>
    )

    function insert(id, type) {
        const data = {
            route: "edit_order_state",
            id: id,
            type: type
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تغير الحالة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}