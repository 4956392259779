import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function EditRelation({state, changeState, refresh, item, id}) {

    const [priority, setPriority] = useState(item.priority);
    const [barcode, setBarcode] = useState(item.barcode);

    return (
        <ButtonModal title='تعديل المادة' btnText="تعديل" state={state} changeState={changeState} btnAction={() => {
            insert(item.id, priority, barcode, id);
        }}>
            الاولوية
            <input type="number" className="form-control form-control-sm mb-3" value={priority} placeholder="الاولوية" onChange={(e) => setPriority(e.target.value)}/>
            الباركود
            <input type="text" className="form-control form-control-sm" value={barcode} placeholder="الباركود" onChange={(e) => setBarcode(e.target.value)}/>
        </ButtonModal>
    )

    function insert(id, priority, barcode, subcategory) {
        const data = {
            route: "edit_relation",
            priority: priority,
            subcategory: subcategory,
            barcode: barcode,
            id: id
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تعديل المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}