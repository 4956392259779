import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function EditShowroom({state, changeState, refresh, showroom}) {

    const [ar_name, setAr_name] = useState(null);
    const [en_name, setEn_name] = useState(null);
    const [kr_name, setKr_name] = useState(null);
    const [lng, setLng] = useState(null);
    const [lat, setLat] = useState(null);

    const [saturdayOpen, setSaturedayOpen] = useState("");
    const [saturdayClose, setSaturedayClose] = useState("");
    const [saturdayIsOpen, setSaturdayIsOpen] = useState(false);

    const [sundayOpen, setSundayOpen] = useState("");
    const [sundayClose, setSundayClose] = useState("");
    const [sundayIsOpen, setSundayIsOpen] = useState(false);

    const [mondayOpen, setMondayOpen] = useState("");
    const [mondayClose, setMondayClose] = useState("");
    const [mondayIsOpen, setMondayIsOpen] = useState(false);

    const [tuesdayOpen, setTuesdayOpen] = useState("");
    const [tuesdayClose, setTuesdayClose] = useState("");
    const [tuesdayIsOpen, setTuesdayIsOpen] = useState(false);

    const [wednesdayOpen, setWednesdayOpen] = useState("");
    const [wednesdayClose, setWednesdayClose] = useState("");
    const [wednesdayIsOpen, setWednesdayIsOpen] = useState(false);

    const [thursdayOpen, setThursdayOpen] = useState("");
    const [thursdayClose, setThursdayClose] = useState("");
    const [thursdayIsOpen, setThursdayIsOpen] = useState(false);

    const [fridayOpen, setFridayOpen] = useState("");
    const [fridayClose, setFridayClose] = useState("");
    const [fridayIsOpen, setFridayIsOpen] = useState(false);

    useEffect(() => {
        setAr_name(showroom.ar_name);
        setEn_name(showroom.en_name);
        setKr_name(showroom.kr_name);
        setLng(showroom.lng);
        setLat(showroom.lat);
        setSaturedayOpen(showroom.saturdayOpen);
        setSaturedayClose(showroom.saturdayClose);
        setSaturdayIsOpen(showroom.saturdayIsOpen);
        setSundayOpen(showroom.sundayOpen);
        setSundayClose(showroom.sundayClose);
        setSundayIsOpen(showroom.sundayIsOpen);
        setMondayOpen(showroom.mondayOpen);
        setMondayClose(showroom.mondayClose);
        setMondayIsOpen(showroom.mondayIsOpen);
        setTuesdayOpen(showroom.tuesdayOpen);
        setTuesdayClose(showroom.tuesdayClose);
        setTuesdayIsOpen(showroom.tuesdayIsOpen);
        setWednesdayOpen(showroom.wednesdayOpen);
        setWednesdayClose(showroom.wednesdayClose);
        setWednesdayIsOpen(showroom.wednesdayIsOpen);
        setThursdayOpen(showroom.thursdayOpen);
        setThursdayClose(showroom.thursdayClose);
        setThursdayIsOpen(showroom.thursdayIsOpen);
        setFridayOpen(showroom.fridayOpen);
        setFridayClose(showroom.fridayClose);
        setFridayIsOpen(showroom.fridayIsOpen);
    }, [showroom])
    
    return (
        <ButtonModal title='Edit' btnText="Edit" state={state} changeState={changeState} btnAction={() => {
            insert(showroom.id, ar_name, en_name, kr_name, lng, lat,
                saturdayOpen, saturdayClose, saturdayIsOpen,
                sundayOpen, sundayClose, sundayIsOpen,
                mondayOpen, mondayClose, mondayIsOpen,
                tuesdayOpen, tuesdayClose, tuesdayIsOpen,
                wednesdayOpen, wednesdayClose, wednesdayIsOpen,
                thursdayOpen, thursdayClose, thursdayIsOpen,
                fridayOpen, fridayClose, fridayIsOpen);
        }}>
            <input type="text" className="form-control form-control-sm mb-3" value={ar_name} placeholder="عربي" onChange={(e) => setAr_name(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={kr_name} placeholder="كردي" onChange={(e) => setKr_name(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={en_name} placeholder="انكليزي" onChange={(e) => setEn_name(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={lng} placeholder="Longitute" onChange={(e) => setLng(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={lat} placeholder="Latitute" onChange={(e) => setLat(e.target.value)}/>

            <div className="row align-items-center mb-3">
                <p>Saturday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={saturdayOpen} placeholder="00:00 AM" onChange={(e) => setSaturedayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={saturdayClose} placeholder="00:00 PM" onChange={(e) => setSaturedayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={saturdayIsOpen} id="flexCheckDefault" onChange={(e) => setSaturdayIsOpen(!saturdayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Sunday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={sundayOpen} placeholder="00:00 AM" onChange={(e) => setSundayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={sundayClose} placeholder="00:00 PM" onChange={(e) => setSundayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={sundayIsOpen} id="flexCheckDefault" onChange={(e) => setSundayIsOpen(!sundayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Monday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={mondayOpen} placeholder="00:00 AM" onChange={(e) => setMondayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={mondayClose} placeholder="00:00 PM" onChange={(e) => setMondayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={mondayIsOpen} id="flexCheckDefault" onChange={(e) => setMondayIsOpen(!mondayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Tuesday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={tuesdayOpen} placeholder="00:00 AM" onChange={(e) => setTuesdayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={tuesdayClose} placeholder="00:00 PM" onChange={(e) => setTuesdayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={tuesdayIsOpen} id="flexCheckDefault" onChange={(e) => setTuesdayIsOpen(!tuesdayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Wednesday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={wednesdayOpen} placeholder="00:00 AM" onChange={(e) => setWednesdayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={wednesdayClose} placeholder="00:00 PM" onChange={(e) => setWednesdayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={wednesdayIsOpen} id="flexCheckDefault" onChange={(e) => setWednesdayIsOpen(!wednesdayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Thursday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={thursdayOpen} placeholder="00:00 AM" onChange={(e) => setThursdayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={thursdayClose} placeholder="00:00 PM" onChange={(e) => setThursdayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={thursdayIsOpen} id="flexCheckDefault" onChange={(e) => setThursdayIsOpen(!thursdayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <p>Friday</p>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={fridayOpen} placeholder="00:00 AM" onChange={(e) => setFridayOpen(e.target.value)}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control form-control-sm" value={fridayClose} placeholder="00:00 PM" onChange={(e) => setFridayClose(e.target.value)}/>
                </div>
                <div className="col form-check">
                    <input class="form-check-input" type="checkbox" checked={fridayIsOpen} id="flexCheckDefault" onChange={(e) => setFridayIsOpen(!fridayIsOpen)} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Open 
                    </label>
                </div>
            </div>
        </ButtonModal>
    )

    function insert(id, ar_name, en_name, kr_name, lng, lat,
        saturdayOpen, saturdayClose, saturdayIsOpen,
        sundayOpen, sundayClose, sundayIsOpen,
        mondayOpen, mondayClose, mondayIsOpen,
        tuesdayOpen, tuesdayClose, tuesdayIsOpen,
        wednesdayOpen, wednesdayClose, wednesdayIsOpen,
        thursdayOpen, thursdayClose, thursdayIsOpen,
        fridayOpen, fridayClose, fridayIsOpen) {
        const data = {
            route: "edit_showroom",
            id: id,
            ar_name: ar_name,
            en_name: en_name,
            kr_name: kr_name,
            lng: lng,
            lat: lat,
            saturdayOpen: saturdayOpen,
            saturdayClose: saturdayClose,
            saturdayIsOpen: saturdayIsOpen,
            sundayOpen: sundayOpen,
            sundayClose: sundayClose,
            sundayIsOpen: sundayIsOpen,
            mondayOpen: mondayOpen,
            mondayClose: mondayClose,
            mondayIsOpen: mondayIsOpen,
            tuesdayOpen: tuesdayOpen,
            tuesdayClose: tuesdayClose,
            tuesdayIsOpen: tuesdayIsOpen,
            wednesdayOpen: wednesdayOpen,
            wednesdayClose: wednesdayClose,
            wednesdayIsOpen: wednesdayIsOpen,
            thursdayOpen: thursdayOpen,
            thursdayClose: thursdayClose,
            thursdayIsOpen: thursdayIsOpen,
            fridayOpen: fridayOpen,
            fridayClose: fridayClose,
            fridayIsOpen: fridayIsOpen,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}