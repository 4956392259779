import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { CustomSelect } from "../../components/select/CustomSelect";
import { Host } from "../../Config";

export function NewSubcategory({state, changeState, refresh, categories}) {

    const [arabic, setArabic] = useState('');
    const [kurdish, setKurdish] = useState('');
    const [english, setEnglish] = useState('');
    const [category, setCategory] = useState('');

    return (
        <ButtonModal title='اضافة' state={state} changeState={changeState} btnAction={() => {
            insert(arabic, kurdish, english, category);
        }}>
            <div className="mb-3">
                <CustomSelect options={categories} handleChange={setCategory} defaultValue={category}/>
            </div>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm" placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>
        </ButtonModal>
    )

    function insert(ar, kr, en, category) {
        const data = {
            route: "new_subcategory",
            arabic: ar,
            kurdish: kr,
            english: en,
            category: category
        }
        console.log( JSON.stringify(data));
        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة فرع صنفي جديد");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}