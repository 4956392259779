import { useState } from "react";
import toast from "react-hot-toast";
import { ImageUploader } from "../../components/imageuploader/ImageUploader";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function SendItemNotification({state, changeState, item}) {

    const [arabic, setArabic] = useState('');
    const [kurdish, setKurdish] = useState('');
    const [english, setEnglish] = useState('');
    const [image, setImage] = useState(null);

    return (
        <ButtonModal title='ارسال اشعار' state={state} changeState={changeState} btnAction={() => {
            insert(arabic, kurdish, english, image, item.id);
        }}>
            <div className="mb-3">
                <ImageUploader image={image} setImage={setImage} />
            </div>
            <input type="text" className="form-control form-control-sm mb-3" value={arabic} placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={kurdish} placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={english} placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>
        </ButtonModal>
    )

    function insert(ar, kr, en, image, id) {
        const data = {
            route: "send_item_notification",
            arabic: ar,
            kurdish: kr,
            english: en,
            image: image,
            id: id
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم ارسال الاشعار");
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}