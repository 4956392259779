import { useState } from "react";
import toast from "react-hot-toast";
import { ImageUploader } from "../../components/imageuploader/ImageUploader";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host, ImageHost } from "../../Config";

export function EditCategory({state, changeState, refresh, item}) {

    const [arabic, setArabic] = useState(item.ar_name);
    const [kurdish, setKurdish] = useState(item.kr_name);
    const [english, setEnglish] = useState(item.en_name);
    const [priority, setPriority] = useState(item.priority);
    const [image, setImage] = useState();

    return (
        <ButtonModal title='تعديل الصنف' state={state} changeState={changeState} btnAction={() => {
            insert(item.id, arabic, kurdish, english, image, priority);
        }}>
            <div className="mb-3">
                <ImageUploader image={image} setImage={setImage} defaultImage={ImageHost + item.image}/>
            </div>
            <input type="text" className="form-control form-control-sm mb-3" value={arabic} placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={kurdish} placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={english} placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>
            <input type="number" className="form-control form-control-sm" value={priority} placeholder="الاولوية" onChange={(e) => setPriority(e.target.value)}/>
        </ButtonModal>
    )

    function insert(id, ar, kr, en, image, priority) {
        const data = {
            route: "edit_category",
            arabic: ar,
            kurdish: kr,
            english: en,
            image: image,
            id: id,
            priority: priority,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تعديل الصنف");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}