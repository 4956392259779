import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "../../../components/datatable/Datatable";
import { CustomSelect } from "../../../components/select/CustomSelect";
import { Host } from '../../../Config';
import { DeletePromotionDetail } from "./DeletePromotionDetail";
import { EditPromotionDetail } from "./EditPromotionDetail";

export function PromotionDetails() {
    const location = useLocation();
    
    const [priority, setPriority] = useState(0);
    const [brand, setBrand] = useState(0);
    const [brands, setBrands] = useState([]);
    const [barcode, setBarcode] = useState('');
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: 'الاولوية', prop: 'priority', isFilterable: true },
        { title: 'الباركود', prop: 'barcode', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                {
                <button type="button" className="btn btn-danger" onClick={() => {
                    setDeleteState(true);
                    setSelectedItem(row)
                }}><i className="bi bi-trash3"></i></button>
                }
                <button type="button" className="btn btn-primary" onClick={() => {
                    setEditState(true);
                    setSelectedItem(row)
                }}><i className="bi bi-pencil"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="row">
                                <div className="col-xl-4 mb-3">
                                    <div className="card">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                        تفاصيل الاعلان
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                الاولوية
                                                <input type="number" className="form-control form-control-sm mb-3" value={priority} placeholder="الاولوية" onChange={(e) => setPriority(e.target.value)}/>
                                                الباركود
                                                <input type="text" className="form-control form-control-sm" value={barcode} placeholder="الباركود" onChange={(e) => 
                                                    setBarcode(e.target.value)
                                                } onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        insertData(barcode, priority)
                                                    }
                                                }}/>
                                                <button className="btn btn-dark w-100 mt-3" onClick={() => {insertData(barcode, priority)}}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                    الاستيراد من العلامات التجارية
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                <CustomSelect options={brands} handleChange={setBrand} defaultValue={brand}/>
                                                
                                                <button className="btn btn-dark w-100 mt-3" onClick={() => {insertAll(brand)}}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                        التفاصيل
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                <DataTable body={data} headers={headers} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DeletePromotionDetail state={deleteState} changeState={setDeleteState} refresh={() => {refresh();}} item={selectedItem} />
                        {
                            selectedItem && editState ? 
                            (
                                <EditPromotionDetail state={editState} changeState={setEditState} refresh={() => {refresh();}} item={selectedItem} />
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    function insertData(barcode, priority) {
        const promotion = getId();

        const data = {
            route: "new_promotion_detail",
            promotion: promotion,
            barcode: barcode,
            priority: priority
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        console.log(JSON.stringify(data));

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    fetchData().then(() => {
                        toast.success("تم اضافة المادة");
                        setBarcode('');
                    });
                    
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }

    function insertAll(brand) {
        const promotion = getId();

        const data = {
            route: "new_promotion_detail_brand",
            promotion: promotion,
            brand: brand
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        console.log(JSON.stringify(data));

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    fetchData().then(() => {
                        toast.success("تم اضافة المادة");
                        setBarcode('');
                    });
                    
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }

    async function refresh() {
        setData(null);
        setSelectedItem(null);
        await fetchData();
    }

    async function fetchData() {
        const id = getId();
        await fetchBrands();

        const data = {
            route: "fetch_promotion_details",
            promotion: id,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Relation = await response.json();
        if (Relation.status.code === 1) {
            setData(Relation.data);
        }
        else {
            setData([]);
        }
    }
    async function fetchBrands() {
        setBrands(null);
        const data = {
            route: "fetch_brands",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            const newData = [];
            brands.data.forEach(e => {
                newData.push({value: e.id, label: e.en_name});
            });
            setBrands(newData);
        }
        else {
            setBrands([]);
        }
    }
}