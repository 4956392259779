import { useState } from 'react'
import { Host } from '../../Config';
import './Login.css'

export function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    return (
        <div className='body'>
            <div className="container" id="container">
                <div className="form-container sign-in-container">
                    <div className='formInput'>
                        <h1 className='h1' style={{marginBottom: '25px'}}>تسجيل الدخول</h1>
                        <input className='inputBox' type="username" placeholder="Email" onChange={(e) => setUsername(e.target.value)} />
                        <input className='inputBox' type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        <button className='button' style={{marginTop: '25px'}} onClick={() => signIn(username, password)}>Sign In</button>
                    </div>
                </div>
            </div>
        </div>
    )

    async function signIn(username, password) {
        const data = {
            route: "login",
            username: username,
            password: password
        }

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });
        var token = response.headers.get('Authorization');
        if (token) {
            localStorage.setItem('token', token);
            window.location.reload();
        }
    }
}