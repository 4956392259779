import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function SetAllSubitemsPrice({state, changeState, refresh, itemId}) {
    const [discount1, setDiscount1] = useState('');
    const [discount2, setDiscount2] = useState('');
    const [discount3, setDiscount3] = useState('');
    const [point1, setPoint1] = useState('');
    const [point2, setPoint2] = useState('');
    const [point3, setPoint3] = useState('');

    return (
        <ButtonModal title='Set all discount' state={state} changeState={changeState} btnAction={() => {
            insert(discount1, discount2, discount3, point1, point2, point3, itemId);
        }}>
            
            <div className="d-flex mb-3">
                <input type="number" className="form-control form-control-sm me-3" placeholder="1 الخصم" onChange={(e) => setDiscount1(e.target.value)}/>
                <input type="number" className="form-control form-control-sm me-3" placeholder="2 الخصم" onChange={(e) => setDiscount2(e.target.value)}/>
                <input type="number" className="form-control form-control-sm" placeholder="3 الخصم" onChange={(e) => setDiscount3(e.target.value)}/>
            </div>
            <div className="d-flex mb-3">
                <input type="number" className="form-control form-control-sm me-3" placeholder="1 بونص" onChange={(e) => setPoint1(e.target.value)}/>
                <input type="number" className="form-control form-control-sm me-3" placeholder="2 بونص" onChange={(e) => setPoint2(e.target.value)}/>
                <input type="number" className="form-control form-control-sm" placeholder="3 بونص" onChange={(e) => setPoint3(e.target.value)}/>

            </div>
        </ButtonModal>
    )

    function insert(discount1, discount2, discount3, point1, point2, point3, item) {
        const data = {
            route: "set_all_prices_subitems",
            discount1: discount1,
            discount2: discount2,
            discount3: discount3,
            point1: point1,
            point2: point2,
            point3: point3,
            item: item,
        }

      
        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}