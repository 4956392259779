import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function EditRegion({state, changeState, refresh, region}) {

    const [ar_name, setAr_name] = useState(region.ar_name);
    const [en_name, setEn_name] = useState(region.en_name);
    const [kr_name, setKr_name] = useState(region.kr_name);
    const [fees, setFees] = useState(region.fees);
    const [branch, setBranch] = useState(region.branch);
    

    useEffect(() => {
        setAr_name(region.ar_name);
        setEn_name(region.en_name);
        setKr_name(region.kr_name);
        setFees(region.fees);
        setBranch(region.branch);
    }, [region]);

    return (
        <ButtonModal title='Edit' btnText="Edit" state={state} changeState={changeState} btnAction={() => {
            insert(ar_name, en_name, kr_name, fees, branch, region.id);
        }}>
            <input type="text" className="form-control form-control-sm mb-3" value={ar_name} placeholder="عربي" onChange={(e) => setAr_name(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={kr_name} placeholder="كردي" onChange={(e) => setKr_name(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={en_name} placeholder="انكليزي" onChange={(e) => setEn_name(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" value={fees} placeholder="رسوم التوصيل" onChange={(e) => setFees(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" value={branch} placeholder="فرع" onChange={(e) => setBranch(e.target.value)}/>
        </ButtonModal>
    )

    function insert(ar_name, en_name, kr_name, fees, branch, id) {
        const data = {
            route: "edit_region",
            ar_name: ar_name,
            en_name: en_name,
            kr_name: kr_name,
            fee: fees,
            branch: branch,
            id: id
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}