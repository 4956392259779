import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';
import { DeletePromotion } from "./DeletePromotion";
import { EditPromotion } from "./EditPromotion";
import { NewPromotion } from "./NewPromotion";
import { SendPromotionNotification } from "./sendPromotionNotification";

export function Promotions() {
    const [addState, setAddState] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [sendItemMessage, setSendItemMessage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const navigate = useNavigate();
    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'عربي', prop: 'ar_name', isFilterable: true },
        { title: 'كردي', prop: 'kr_name', isFilterable: true },
        { title: 'انكليزي', prop: 'en_name', isFilterable: true },
        { title: 'عدد المواد', prop: 'total', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    {
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setDeleteState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-trash3"></i></button>
                     }
                     <button type="button" className="btn btn-warning" onClick={() => {
                        setSendItemMessage(true);
                        setSelectedItem(row)
                    }}><i className="bi-chat-left-dots"></i></button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        setEditState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-pencil"></i></button>
                    <button type="button" className="btn btn-dark" onClick={() => {
                        navigate('/promotiondetails?id=' + row.id)
                    }}><i className="bi bi-list"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                الاعلانات
                                            </div>
                                            <div className="col" align="right">
                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    setAddState(true);
                                                }}><i className="bi bi-plus"></i> اضافة</button>
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NewPromotion state={addState} changeState={setAddState} refresh={() => {fetchData();}} />
                        <DeletePromotion state={deleteState} changeState={setDeleteState} refresh={() => {fetchData();}} item={selectedItem} />
                        {
                            selectedItem && editState ? 
                            (
                                <EditPromotion state={editState} changeState={setEditState} refresh={() => {fetchData();}} item={selectedItem} />
                            ) : 
                            <></>
                        }
                        {
                            selectedItem && sendItemMessage ? 
                            (
                                <SendPromotionNotification state={sendItemMessage} changeState={setSendItemMessage} item={selectedItem} />
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    async function fetchData() {
        setData(null);
        setSelectedItem(null);

        const data = {
            route: "fetch_promotions",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            setData(categories.data);
        }
        else {
            setData([]);
        }
    }
}