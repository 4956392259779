import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function AddCouponsToAll({state, changeState, refresh, item}) {

    const [arabic, setArabic] = useState('');
    const [kurdish, setKurdish] = useState('');
    const [english, setEnglish] = useState('');


    const [arabicMsg, setArabicMsg] = useState('');
    const [kurdishMsg, setKurdishMsg] = useState('');
    const [englishMsg, setEnglishMsg] = useState('');

    const [arabicdesc, setArabicDesc] = useState('');
    const [kurdishdesc, setKurdishDesc] = useState('');
    const [englishdesc, setEnglishDesc] = useState('');
    const [amount, setAmount] = useState(0);
    const [overThan, setOverThan] = useState(0);
    const [expire, setExpire] = useState('');

    return (
        <ButtonModal title='اضافة خصم جديد' state={state} changeState={changeState} btnAction={() => {
            insert(arabic, kurdish, english, arabicdesc, kurdishdesc, englishdesc, amount, overThan, expire, item?.id, arabicMsg, kurdishMsg, englishMsg);
        }}>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف العربي" onChange={(e) => setArabicDesc(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف الكردي" onChange={(e) => setKurdishDesc(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف الانكليزي" onChange={(e) => setEnglishDesc(e.target.value)}/>
            
            <input type="number" className="form-control form-control-sm mb-3" placeholder="القيمة" onChange={(e) => setAmount(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" placeholder="اكثر من" onChange={(e) => setOverThan(e.target.value)}/>
            <input type="datetime-local" className="form-control form-control-sm mb-3" placeholder="تاريخ الانتهاء" onChange={(e) => setExpire(e.target.value)}/>
        
        
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الاشعارات العربي" onChange={(e) => setArabicMsg(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الاشعارات الكردي" onChange={(e) => setKurdishMsg(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الاشعارات الانكليزي" onChange={(e) => setEnglishMsg(e.target.value)}/>
            
        </ButtonModal>
    )

    function insert(ar, kr, en, ardesc, krdesc, endesc, amount, overthan, expire, id, arabicMsg, kurdishMsg, englishMsg) {
        const data = {
            route: "new_coupon",
            arabic: ar,
            kurdish: kr,
            english: en,

            arabicMsg: arabicMsg,
            kurdishMsg: kurdishMsg,
            englishMsg: englishMsg,

            arabic_desc: ardesc,
            kurdish_desc: krdesc,
            english_desc: endesc,
            amount: amount,
            over_than: overthan,
            expire: expire,
            id: id ?? null
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة الخصم");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}