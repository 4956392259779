import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';
import { DeleteSuggetions } from "./DeleteSuggetions";

export function Suggetions() {
    const location = useLocation();
    
    const [barcode, setBarcode] = useState('');
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: 'الباركود', prop: 'barcode', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setDeleteState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-trash3"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="row">
                                <div className="col-xl-4 mb-3">
                                    <div className="card">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                        المواد المكملة
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                الباركود
                                                <input type="text" className="form-control form-control-sm" value={barcode} placeholder="الباركود" onChange={(e) => 
                                                    setBarcode(e.target.value)
                                                } onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        insertData(barcode)
                                                    }
                                                }}/>
                                                <button className="btn btn-dark w-100 mt-3" onClick={() => {insertData(barcode)}}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                        التفاصيل
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                <DataTable body={data} headers={headers} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DeleteSuggetions state={deleteState} changeState={setDeleteState} refresh={() => {refresh();}} item={selectedItem} />
                        
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    function insertData(barcode) {
        const promotion = getId();

        const data = {
            route: "new_suggestion",
            item: promotion,
            barcode: barcode
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        console.log(JSON.stringify(data));

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    fetchData().then(() => {
                        toast.success("تم اضافة المادة");
                        setBarcode('');
                    });
                    
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }

    async function refresh() {
        setData(null);
        setSelectedItem(null);
        await fetchData();
    }

    async function fetchData() {
        const id = getId();

        const data = {
            route: "fetch_suggestions",
            item: id,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Relation = await response.json();
        if (Relation.status.code === 1) {
            setData(Relation.data);
        }
        else {
            setData([]);
        }
    }
}