import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';
import { AddBounce } from "./AddBounce";
import { AddCouponsToAll } from "./AddCouponsToAll";
import { AddCustomer } from "./AddCustomer";
import { BanCustomer } from "./BanCustomer";
import { EditCustomer } from "./EditCustomer";
import { SendNotification } from "./SendNotification";
import { SendNotificationToAll } from "./SendNotificationToAll";
import { UnBanCustomer } from "./UnBanCustomer";

export function Customers() {
    const navigate = useNavigate();

    const [banState, setBanState] = useState(false);
    const [addCustomer, setAddCustomer] = useState(false);
    const [unbanState, setUnBanState] = useState(false);
    const [couponState, setAddCouponState] = useState(false);
    const [bounceState, setAddBounceState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [messageState, setMessageState] = useState(false);
    const [messageAllState, setMessageAllState] = useState(false);
    const [editCustomer, setEditCustomer] = useState(false);

    const [permission, setPermission] = useState(false);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);


    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'اسم المستخدم', prop: 'username', isFilterable: true },
        { title: 'رقم الهاتف', prop: 'phone', isFilterable: true },
        { title: 'محظور', prop: 'active', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    {
                        row.active ? 
                        <button type="button" className="btn btn-danger" onClick={() => {
                            setBanState(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-x-lg"></i></button> 
                        : 
                        <button type="button" className="btn btn-success" onClick={() => {
                            setUnBanState(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-check-lg"></i></button>
                    }
                    
                    {
                        permission ? 
                        <button type="button" className="btn btn-warning" onClick={() => {
                            setEditCustomer(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-pencil"></i></button> : <></>    
                    }

                    <button type="button" className="btn btn-dark" onClick={() => {
                        setAddCouponState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-plus-lg"></i></button>

                    <button type="button" className="btn btn-warning" onClick={() => {
                        setAddBounceState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-plus-lg"></i></button>

                    <button type="button" className="btn btn-primary" onClick={() => {
                        setMessageState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-chat-left-dots"></i></button>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        navigate("/coupons?id=" + row.id);
                    }}><i className="bi bi-list"></i></button>
                </div>
            )
        }
    ];

    const headers2 = [
        { title: 'اسم المستخدم', prop: 'username', isFilterable: true },
        { title: 'رقم الهاتف', prop: 'phone', isFilterable: true },
        { title: 'محظور', prop: 'active', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    {
                        row.active ? 
                        <button type="button" className="btn btn-danger" onClick={() => {
                            setBanState(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-x-lg"></i></button> 
                        : 
                        <button type="button" className="btn btn-success" onClick={() => {
                            setUnBanState(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-check-lg"></i></button>
                    }
                    
                    {
                        permission ? 
                        <button type="button" className="btn btn-warning" onClick={() => {
                            setEditCustomer(true);
                            setSelectedItem(row)
                        }}><i className="bi bi-pencil"></i></button> : <></>    
                    }

                    <button type="button" className="btn btn-dark" onClick={() => {
                        setAddCouponState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-plus-lg"></i></button>

                    <button type="button" className="btn btn-warning" onClick={() => {
                        setAddBounceState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-plus-lg"></i></button>

                    <button type="button" className="btn btn-primary" onClick={() => {
                        setMessageState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-chat-left-dots"></i></button>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        navigate("/coupons?id=" + row.id);
                    }}><i className="bi bi-list"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                الزبائن
                                            </div>
                                            <div className="col" align="right">
                                                <button className="btn btn-sm btn-success me-3" onClick={() => {
                                                    setMessageAllState(true);
                                                }}><i className="bi bi-chat-left-dots"></i> ارسال اشعار للجميع</button>
                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    setAddCouponState(true);
                                                    setSelectedItem(null);
                                                }}><i className="bi bi-plus"></i> اضافة خصم للجميع</button>
                                                
                                                {
                                                    permission ? 
                                                    <button type="button" className="btn btn-sm btn-info ms-3" onClick={() => {
                                                        setAddCustomer(true);
                                                    }}><i className="bi bi-plus-lg"></i> إضافة عميل</button> : <></>    
                                                }
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={permission ? headers2 : headers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BanCustomer state={banState} changeState={setBanState} refresh={() => {fetchData();}} item={selectedItem} />
                        <UnBanCustomer state={unbanState} changeState={setUnBanState} refresh={() => {fetchData();}} item={selectedItem} />
                        <AddCouponsToAll state={couponState} changeState={setAddCouponState} refresh={() => {fetchData();}} item={selectedItem} />
                        <SendNotification state={messageState} changeState={setMessageState} item={selectedItem} />
                        <SendNotificationToAll state={messageAllState} changeState={setMessageAllState} />
                        {
                            selectedItem && editCustomer ? 
                            <EditCustomer state={editCustomer} changeState={setEditCustomer} customer={selectedItem} regions={regions} refresh={() => {fetchData();}} cities={cities} /> : <></>
                        }
                        {
                            addCustomer ? 
                            <AddCustomer state={addCustomer} changeState={setAddCustomer} regions={regions} refresh={() => {fetchData();}} cities={cities} /> : <></>
                        }
                        {
                            bounceState ? 
                            <AddBounce state={bounceState} changeState={setAddBounceState} item={selectedItem} /> : <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    async function fetchData() {
        await fetchRegions();
        await fetchCities();

        setData(null);

        const data = {
            route: "fetch_customers",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var customers = await response.json();
        if (customers.status.code === 1) {
            setData(customers.data);
            console.log(customers.data);
            setPermission(customers.permission);
        }
        else {
            setData([]);
        }
    }
    async function fetchRegions() {
        setRegions(null);
        const data = {
            route: "fetch_regions",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            const newData = [];
            brands.data.forEach(e => {
                newData.push({value: e.id, label: e.name});
            });
            setRegions(newData);
        }
        else {
            setRegions([]);
        }
    }

    async function fetchCities() {
        setCities(null);
        const data = {
            route: "fetch_cities",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            const newData = [];
            brands.data.forEach(e => {
                newData.push({value: e.id, label: e.name});
            });
            setCities(newData);
        }
        else {
            setCities([]);
        }
    }
}