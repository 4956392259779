import { useEffect, useState } from "react"
import toast, { Toaster } from "react-hot-toast";
import { Host } from "../../Config";

export function Settings() {
    
    const [dollar, setDollar] = useState();
    useEffect(() => {
        fetchDollar();
    }, []);
    return <>
        <div className="pt-3 pe-3 ps-3 pb-3">
            <div className="row">
                <div className="col-xl-4 mb-3">
                    <div className="card">
                        <div className='card-header'>
                            <div className="row align-items-center">
                                <div className="col fw-bold">
                                اعدادات 
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="">
                                دولار
                                <input type="number" className="form-control form-control-sm mb-3" value={dollar} placeholder="دولار" onChange={(e) => setDollar(e.target.value)}/>
                                <button className="btn btn-dark w-100 mt-3" onClick={() => {
                                    saveDollar(dollar)
                                }}>احفظ</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toaster position="top-center" />
    </>

    function saveDollar(price) {
        const data = {
            route: "save_dollar",
            price: price
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم الحفظ");
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }

    async function fetchDollar() {
        const data = {
            route: "fetch_dollar",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            setDollar(brands.settings.dollar);
        }
        else {
            setDollar(0);
        }
    }
}