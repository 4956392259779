import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Host } from "../../Config";
import { MainRouting } from "../../routes";
import MenuItems from "./config.json";
import "./sidebar.css";

export const Sidebar = () => {

    const [activeElement, setActiveElement] = useState(false)
    const [title, setTitle] = useState('')
    const [permissions, setPermissions] = useState(null)


    useEffect(() => {
        fetchPermissions();
    }, []);

    return (<>
        {
            permissions ? (
                <div>
                <div className="sidebar close">
                    <div className="logo-details">
                        <i className="bi bi-shop"></i>
                        <span className="logo_name">باغي خاتينا</span>
                    </div>
                    <ul className="nav-links">
    
                        {
                            MenuItems.map((menu, index) => (
                                <>
                                    {
                                        permissions[menu.id] ? (
                                            <li key={index}>
                                                <div className="iocn-link">
                                                    <Link to={menu.to} onClick={() => { setTitle(menu.name) }}>
                                                        <i className={menu.iconClass} />
                                                        <p className="link_name pt-3" to={menu.to}>{menu.name}</p>
                                                    </Link>
                                                    {
                                                        menu.subMenus && menu.subMenus.length > 0 ?
                                                            (
                                                                <i className='bi bi-arrow-down-short arrow' onClick={(e) => {e.target.parentElement.parentElement.classList.toggle("showMenu")}} ></i>
                                                            ) : <></>
                                                    }
                                                </div>
                                                {
                                                    menu.subMenus && menu.subMenus.length > 0
                                                    ? (
                                                        <>
                                                            <ul className="sub-menu">
                                                            {
                                                                menu.subMenus.map((sub, i) => (
                                                                    <div key={i}>
                                                                        <Link to={sub.to} onClick={() => { setTitle(sub.name) }}>
                                                                            {sub.name}
                                                                        </Link>
                                                                    </div>
                                                                ))
                                                            }
                                                            </ul>
                                                        </>
                                                    ) : <></>
                                                }

                                            </li>
                                        ) : <></>
                                    }
                                </>
                            ))
                        }
                        <div className="ms-4 me-4 mb-3 mt-3" style={{ height: "1px", backgroundColor: "rgba(0,0,0, 0.2)" }} />
                        <li>
                            <div className="iocn-link">
                                <Link to='/' onClick={() => { localStorage.clear(); window.location.reload(); }}>
                                    <i className='bi bi-box-arrow-in-left' />
                                    <p className="link_name pt-3" onClick={() => { localStorage.clear(); window.location.reload(); }} to='/'>Logout</p>
                                </Link>
                            </div>
                        </li>
                    </ul>
    
                </div>
                <section className="home-section">
                    <div className="home-content">
                        <i className='bi bi-list bx-menu' onClick={() => {
                            if (!activeElement)
                                setActiveElement(true)
                            let sidebar = document.querySelector(".sidebar");
                            sidebar.classList.toggle("close");
                        }}></i>
                        <i className='bi bi-arrow-left me-3' onClick={() => {window.history.back()}} />
                        <i className='bi bi-arrow-right me-3' onClick={() => {window.history.forward()}}/>
                        <i className='bi bi-arrow-clockwise' onClick={() => {window.location.reload()}}/>
                    </div>
                    <div style={{backgroundColor: "rgb(245,245,245)", minHeight: "100%", paddingTop: "60px"}}>
                        <MainRouting />
                    </div>
                </section>
            </div>
            ) :  (
                <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                    <Spinner />
                </div>
            )
        }
    </>)

    async function fetchPermissions() {
        setPermissions(null);

        const data = {
            route: "fetch_permissions",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            console.log(brands);
            if (brands.permissions.items === 1) {
                brands.permissions.search = 1;
            }
            setPermissions(brands.permissions);
        }
        else {
            setPermissions([]);
        }
    }

}  