import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { CustomSelect } from "../../components/select/CustomSelect";
import { Host } from "../../Config";

export function EditCustomer({state, changeState, refresh, customer, regions, cities}) {

    const [username, setUsername] = useState(customer.username);
    const [phone, setPhone] = useState(customer.phone);
    const [name, setName] = useState(customer.name);
    const [apartment, setApartment] = useState(customer.apartment);
    const [floor, setFloor] = useState(customer.floor);
    const [building, setBuilding] = useState(customer.building);
    const [region, setRegion] = useState(customer.region);
    const [address, setAddress] = useState(customer.address);
    const [city, setCity] = useState(customer.city);
    
    return (
        <ButtonModal title='تعديل' btnText="تعديل" state={state} changeState={changeState} btnAction={() => {
            insert(customer.id, username, phone, name, apartment, floor, building, region, address, city);
        }}>
            <input type="text" className="form-control form-control-sm mb-3" value={username} placeholder="اسم المستخدم" onChange={(e) => setUsername(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={phone} placeholder="رقم الهاتف" onChange={(e) => setPhone(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" value={name} placeholder="اسم الموقع" onChange={(e) => setName(e.target.value)}/>

            {
                /*
                <input type="text" className="form-control form-control-sm mb-3" value={apartment} placeholder="شقة" onChange={(e) => setApartment(e.target.value)}/>
                <input type="text" className="form-control form-control-sm mb-3" value={floor} placeholder="طابق" onChange={(e) => setFloor(e.target.value)}/>
                <input type="text" className="form-control form-control-sm mb-3" value={building} placeholder="المبني" onChange={(e) => setBuilding(e.target.value)}/>
                */
            }

            <textarea className="form-control form-control-sm mb-3" value={address} placeholder="العنوان" onChange={(e) => setAddress(e.target.value)}/>

            <div className="mb-3">
                <CustomSelect options={regions} handleChange={setRegion} defaultValue={region}/>
            </div>
            <div className="mb-3">
                <CustomSelect options={cities} handleChange={setCity} defaultValue={city}/>
            </div>
            
        </ButtonModal>
    )

    function insert(id, username, phone, name, apartment, floor, building, region, address, city) {
        const data = {
            route: "edit_customer",
            id: id,
            username: username,
            phone: phone,
            name: name,
            apartment: apartment,
            floor: floor,
            building: building,
            region: region,
            address: address,
            city: city
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تعديل المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}