import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';
import { DeleteRelation } from "./DeleteRelation";
import { EditRelation } from "./EditRelation";
import { NewRelation } from "./NewRelation";

export function InnerRelations() {
    const location = useLocation();
    
    const [priority, setPriority] = useState(0);
    const [barcode, setBarcode] = useState('');
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: 'الاولوية', prop: 'priority', isFilterable: true },
        { title: 'الباركود', prop: 'barcode', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            
            cell: (row) => (
                
                <div>
                    {
                        getId() != 0 ?
                        (
                            <div className="btn-group btn-group-sm">
                                <button type="button" className="btn btn-danger" onClick={() => {
                                    setDeleteState(true);
                                    setSelectedItem(row)
                                }}><i className="bi bi-trash3"></i></button>
                                <button type="button" className="btn btn-dark" onClick={() => {
                                    setEditState(true);
                                    setSelectedItem(row)
                                }}><i className="bi bi-pencil"></i></button>
                            </div>
                        ) : <></>
                    }
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="row">
                                {
                                    getId() != 0 ? 
                                    (
                                        <div className="col-xl-4 mb-3">
                                            <div className="card">
                                                    <div className='card-header'>
                                                        <div className="row align-items-center">
                                                            <div className="col fw-bold">
                                                                العلاقات
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className='card-body'>
                                                    <div className="">
                                                        الاولوية
                                                        <input type="number" className="form-control form-control-sm mb-3" value={priority} placeholder="الاولوية" onChange={(e) => setPriority(e.target.value)}/>
                                                        الباركود
                                                        <input type="text" className="form-control form-control-sm" value={barcode} placeholder="الباركود" onChange={(e) => 
                                                            setBarcode(e.target.value)
                                                        } onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                insertData(priority, barcode)
                                                            }
                                                        }}/>
                                                        <button className="btn btn-dark w-100 mt-3" onClick={() => {insertData(priority, barcode)}}>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ): <></>
                                }
                                
                                <div className="col">
                                    <div className="card">
                                            <div className='card-header'>
                                                <div className="row align-items-center">
                                                    <div className="col fw-bold">
                                                        العلاقات
                                                    </div>
                                                </div>
                                            </div>
                                        <div className='card-body'>
                                            <div className="">
                                                <DataTable body={data} headers={headers} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DeleteRelation state={deleteState} changeState={setDeleteState} refresh={() => {refresh();}} item={selectedItem} />
                        {
                            selectedItem && editState ? 
                            (
                                <EditRelation state={editState} changeState={setEditState} refresh={() => {refresh();}} item={selectedItem} id={getId()}/>
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    function insertData(priority, barcode) {
        const subcategory = getId();

        const data = {
            route: "new_relation",
            priority: priority,
            subcategory: subcategory,
            barcode: barcode
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        console.log(JSON.stringify(data));

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    fetchData().then(() => {
                        toast.success("Relation created successfully.");
                        setBarcode('');
                        setPriority(0);
                    });
                    
                }
                else {
                    toast.error("There was something wrong, contact the developer.");
                }
            })
        }).catch(() => {
            toast.error("There was something wrong on sending the request.");
        })
    }

    async function refresh() {
        setData(null);
        setSelectedItem(null);
        await fetchData();
    }

    async function fetchData() {
        const id = getId();

        const data = {
            route: "fetch_relations",
            subcategory: id,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Relation = await response.json();
        if (Relation.status.code === 1) {
            setData(Relation.data);
        }
        else {
            setData([]);
        }
    }
}