import ReactApexChart from "react-apexcharts";

export const OrdersPieChart = ({data = [0,0,0,0,0]}) => {
    const info = {
        
        series: data,
        options: {
          chart: {
            height: '100%',
            type: 'pie',
          },
          labels: ['المعلقات', 'الماكدات', 'تحت التوصيل', 'التامة', 'الملغيات'],
          responsive: [{
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }],
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex]
              return [name, val.toFixed(1) + '%']
            }
          },
          legend: {
            show: false
          }
        },
          
    }
    return (
        <div className="">
            <div className="card">
                    <div className='card-header'>
                        <div className="row align-items-center">
                            <div className="col fw-bold">
                            جميع الطلبات
                            </div>
                        </div>
                    </div>
                <div className='card-body'>
                    <div className="">
                        <ReactApexChart
                            options={info.options}
                            series={info.series}
                            type="pie"
                            height={335}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}