import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';

export function SearchBarcode() {
    const [addState, setAddState] = useState(false);

    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'المادة', prop: 'item', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button type="button" className={"btn btn-secondary"} onClick={() => {
                        window.open("/subitem?id=" + row.item, '_blank');
                    }}><i className={'bi bi-info-lg'}></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);
    const [barcode, setBarcode] = useState("");

    return (
        <>
            {
                (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col" align="right">
                                                
                                                <input type="text" className="form-control form-control-sm" value={barcode} placeholder="الباركود" onChange={(e) => 
                                                    setBarcode(e.target.value)
                                                } onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        fetchData(barcode)
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data ? data : []} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    async function fetchData(barcode) {
        setData(null);
        const data = {
            route: "search_barcode",
            barcode: barcode
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Item = await response.json();
        if (Item.status.code === 1) {
            setData(Item.data);
        }
        else {
            setData([]);
        }
    }
}