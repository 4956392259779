import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function DeletePromotion({state, changeState, refresh, item}) {

    return (
        <ButtonModal title='مسح الاعلان' btnText="مسح" state={state} changeState={changeState} btnAction={() => {
            insert(item.id);
        }}>
            <h6>هل انت متاكد من مسح الاعلان ؟</h6>
        </ButtonModal>
    )

    function insert(id) {
        const data = {
            route: "delete_promotion",
            id: id
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم مسح الاعلان");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}