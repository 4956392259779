import React, { useState } from 'react'

export const ImageUploader = ({image, setImage, defaultImage}) => {
    const [dragging, setDragging] = useState(false)
    
    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false)
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(e.dataTransfer.files[0]);
        }
        
        setDragging(false)
    };

    const handleInput = e => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
            setImage(event.target.result)
        }
    }
    
    var style = {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        height: '100px',
        border: dragging?'3px rgba(0,0,0,0.5) dashed':'3px rgba(0,0,0,0.2) dashed',
        marginBottom: '20px'
    }
    

    return (
        <div
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
            style={style}>
            {
            image || defaultImage ? 
                <img height="100%" src={image ?? defaultImage} /> :
                dragging ? 
                    <div>افلات</div> : 
                    <div>اسحب و افلت او اضغط على المربع لرفع الصورة</div>
            }
            <input type="file" onChange={(e) => handleInput(e)} style={{opacity: "0.0", position: "absolute", top: "0", left: "0", bottom: "0", right: "0", width: "100%", height:"100px"}} />
        </div>
    )
}