import { useState } from "react";
import toast from "react-hot-toast";
import { ImageUploader } from "../../components/imageuploader/ImageUploader";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function NewPromotion({state, changeState, refresh}) {

    const [arabic, setArabic] = useState('');
    const [kurdish, setKurdish] = useState('');
    const [english, setEnglish] = useState('');
    const [showItems, setShowItems] = useState(false);
    const [orderValue, setOrderValue] = useState(1);
    const [showBig, setShowBig] = useState(true);
    const [image, setImage] = useState(null);

    return (
        <ButtonModal title='اضافة اعلان جديد' state={state} changeState={changeState} btnAction={() => {
            insert(arabic, kurdish, english, image, showItems, orderValue, showBig);
        }}>
            <div className="mb-3">
                <ImageUploader image={image} setImage={setImage} />
            </div>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" value={orderValue} placeholder="ترتيب" onChange={(e) => setOrderValue(e.target.value)}/>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" checked={showItems} id="flexCheckDefault" onChange={(e) => setShowItems(!showItems)} />
                <label class="form-check-label" for="flexCheckDefault">
                عرض العناصر
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" checked={showBig} id="flexCheckDefault" onChange={(e) => setShowBig(!showBig)} />
                <label class="form-check-label" for="flexCheckDefault">
                عرض 
                </label>
            </div>
        </ButtonModal>
    )

    function insert(ar, kr, en, image, show_items, order_value, show_big) {
        const data = {
            route: "new_promotion",
            arabic: ar,
            kurdish: kr,
            english: en,
            image: image,
            show_items: show_items ? 1 : 0,
            order_value: order_value,
            show_big: show_big ? 1 : 0
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة اعلان");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}