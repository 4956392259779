import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host, MainHost } from '../../Config';

export function CompletedOrders() {

    const navigate = useNavigate();

    const headers = [
        { title: '#', prop: 'order_id', isFilterable: true },
        { title: 'رقم الهاتف', prop: 'phone', isFilterable: true },
        { title: 'الخصم', prop: 'discount', isFilterable: true },
        { title: 'المجموع', prop: 'total', isFilterable: true },
        { title: 'المجموع بعد الخصم', prop: 'total_after_discount', isFilterable: true },
        { title: 'تاريخ التوصيل', prop: 'delivered_at', isFilterable: true },
        { title: 'تاريخ الطلب', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button type="button" className="btn btn-dark" onClick={() => {
                        navigate('/order_details?id=' + row.id)
                    }}><i className="bi bi-list"></i></button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                        window.open(MainHost + 'bakhekhatina/api/private/print_order.php?id=' + row.id, '_blank');
                    }}><i className="bi bi-printer"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                طلبات التامة
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    async function fetchData() {
        setData(null);

        const data = {
            route: "fetch_orders",
            type: 4,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            setData(categories.data);
        }
        else {
            setData([]);
        }
    }
}