import { useEffect, useState } from "react"
import { OrdersChart } from "./orders_chart"
import { OrdersPieChart } from "./orders_pie_chart";
import { Host } from '../../Config';
import { PendingOrders } from "../orders/PendingOrders";

export const Dashboard = () => {

    const [data, setData] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="mt-3 ms-3 me-3">
                <div className="row">
                    <div className="col-6">
                        <OrdersChart data={data} />
                    </div>
                    <div className="col-6">
                        <OrdersPieChart data={data} />
                    </div>
                </div>
            </div>
            <PendingOrders />
        </>
    )

    async function fetchData() {
        const _data = {
            route: "dashboard",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');
        var response = await fetch(Host, {
            body: JSON.stringify(_data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var json = await response.json();
        if (json.status && json.status.code === 1) {
            let newData = [];
            for(var i = 0; i < 5; i++) {
                var founded = false;
                for(var j = 0; j < 5; j++) {
                    if (json.data[j] && json.data[j].status == i) {
                        founded = true;
                        newData.push(json.data[j].counted);
                    }
                }
                if (founded == false) {
                    newData.push(0);
                }
            }
            setData(newData);
        }
    }
}