import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host, MainHost } from '../../Config';
import { EditOrderState } from "./EditOrderState";

export function OrderDetails() {
    const headers = [
        { title: 'المادة', prop: 'name', isFilterable: true },
        { title: 'الباركود', prop: 'barcode', isFilterable: true },
        { title: 'كلاس', prop: 'class', isFilterable: true },
        { title: 'العدد', prop: 'quantity', isFilterable: true },
        { title: 'السعر', prop: 'price', isFilterable: true },
        { title: 'المجموع', prop: 'total', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => orderInfo.order_state ===0 && (
                <div className="btn-group btn-group-sm">
                    
                    <button type="button" className="btn btn-danger" onClick={() => {
                        removeOrder(row.id)
                    }}><i className={"bi bi-" + (Number(row.quantity) > 1 ? "dash": "trash3")}></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);
    const [orderInfo, setOrderInfo] = useState(null);

    const location = useLocation();


    const [confirmState, setConfirmState] = useState(false);
    const [cancelState, setCancelState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="pt-3 ps-3 pb-3">
                                    <div className="card">
                                        <div className='card-header'>
                                            <div className="row align-items-center">
                                                <div className="col fw-bold">
                                                    معلومات الزبون
                                                </div>
                                            </div>
                                        </div>
                                    <div className='card-body'>
                                        <div className="">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">الزبون</th>
                                                            <th scope="col">رقم الهاتف</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{orderInfo.username}</td>
                                                            <td>{orderInfo.phone}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                            </div>
                            <div className="col">
                                <div className="pt-3 pe-3 pb-3">
                                    <div className="card">
                                        <div className='card-header'>
                                            <div className="row align-items-center">
                                                <div className="col fw-bold">
                                                    الموقع
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div className="">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">الاسم</th>
                                                                <th scope="col">الشقة</th>
                                                                <th scope="col">البناية</th>
                                                                <th scope="col">الطابق</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{orderInfo.name}</td>
                                                                <td>{orderInfo.apartment}</td>
                                                                <td>{orderInfo.building}</td>
                                                                <td>{orderInfo.floor}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                معلومات الطلبية
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">اجراءات</th>
                                                        <th scope="col">
                                                            {
                                                                orderInfo.order_state < 3 ? 
                                                                (<>
                                                                    <div className="btn-group btn-group-sm">
                                                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                                                            setCancelState(true);
                                                                            setSelectedItem(orderInfo)
                                                                        }}><i className="bi bi-x-lg"></i></button>
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={() => {
                                                                            setConfirmState(true);
                                                                            setSelectedItem(orderInfo)
                                                                        }}><i className="bi bi-check-lg"></i></button>
                                                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                                                                            window.open(MainHost + 'bakhekhatina/api/private/print_order.php?id=' + orderInfo.id, '_blank');
                                                                        }}><i className="bi bi-printer"></i></button>
                                                                    </div>
                                                                </>) : 
                                                                (<>
                                                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                                                                        window.open(MainHost + 'bakhekhatina/api/private/print_order.php?id=' + orderInfo.id, '_blank');
                                                                    }}><i className="bi bi-printer"></i></button>
                                                                </>)
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">{orderInfo.order_id}</th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <td scope="col">ملاحظات</td>
                                                        <td scope="col">{orderInfo.notes}</td>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">النوع</th>
                                                        <th scope="col">المقدار</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>المجموع</td>
                                                        <td>{orderInfo.total}</td>
                                                    </tr>
                                                    <tr className="border-bottom border-dark">
                                                        <td>رسوم التوصيل</td>
                                                        <td>{orderInfo.fee}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>المجموع مع التوصيل</td>
                                                        <td>{Number(orderInfo.total) + Number(orderInfo.fee)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>الخصم</td>
                                                        <td>-{orderInfo.discount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>بونص</td>
                                                        <td>-{orderInfo.bounce}</td>
                                                    </tr>
                                                    <tr className="border-bottom border-dark">
                                                        <td>كوبون</td>
                                                        <td>-{orderInfo.coupon}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>المجموع بعد الخصم</td>
                                                        <td>{Number(orderInfo.total) - Number(orderInfo.discount) - Number(orderInfo.bounce) - Number(orderInfo.coupon) + Number(orderInfo.fee)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>هدية بونص</td>
                                                        <td>{Number(orderInfo.gift_bounce)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                تفاصيل الطلب 
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            orderInfo.order_state >=0 ?
                            (<>
                                <EditOrderState state={confirmState} changeState={setConfirmState} refresh={() => {window.history.back()}} item={selectedItem} type={orderInfo.order_state + 1} />
                                <EditOrderState state={cancelState} changeState={setCancelState} refresh={() => {window.history.back()}} item={selectedItem} type={4} />
                                
                            </>) : (<></>)
                        } 
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }     
            <Toaster position="top-center" />
        </>
    )

    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    async function removeOrder(orderItem) {
        const orderId = getId();
        const data = {
            route: "remove_item_from_order",
            order: orderId,
            orderItem: orderItem
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            //await fetchData()
            window.location.reload();
        }
        else {
            toast.error("حذث خطاء ما");
        }
    }

    async function fetchOrderInfo(reset = true) {
        if (reset) {
            setOrderInfo(null);
        }

        const orderId = getId();
        const data = {
            route: "fetch_order_info",
            order: orderId,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            setOrderInfo(categories.data);
        }
        else {
            if (reset) {
                setOrderInfo();
            }
        }
    }

    async function fetchData() {
        await fetchOrderInfo();
        setData(null);

        const orderId = getId();
        const data = {
            route: "fetch_order_details",
            order: orderId,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            setData(categories.data);
        }
        else {
            setData([]);
        }
    }
}