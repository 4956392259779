import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { CustomSelect } from "../../components/select/CustomSelect";
import { Host } from '../../Config';
import { DeleteItem } from "./DeleteItem";
import { EditItem } from "./EditItem";
import { NewItem } from "./NewItem";
import { SendItemNotification } from "./SendItemNotification";

export function Items() {
    const navigate = useNavigate();

    const [addState, setAddState] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [sendItemMessage, setSendItemMessage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'عربي', prop: 'ar_name', isFilterable: true },
        { title: 'كردي', prop: 'kr_name', isFilterable: true },
        { title: 'انكليزي', prop: 'en_name', isFilterable: true },
        { title: 'الماركة', prop: 'brand', isFilterable: true },
        { title: 'سعر 1', prop: 'price1', isFilterable: true },
        { title: 'سعر 2', prop: 'price2', isFilterable: true },
        { title: 'سعر 3', prop: 'price3', isFilterable: true },
        { title: "الحد الاقصي", prop: 'limit', isFilterable: true },
        { title: 'المستخدم', prop: 'created_by', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    {/*
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setDeleteState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-trash3"></i></button>
                    * */}
                    <button type="button" className="btn btn-dark" onClick={() => {
                        setEditState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-pencil"></i></button>
                    <button type="button" className="btn btn-warning" onClick={() => {
                        setSendItemMessage(true);
                        setSelectedItem(row)
                    }}><i className="bi-chat-left-dots"></i></button>
                    <button type="button" className={"btn btn-" + (row.visible ? 'success' : 'primary')} onClick={() => {
                        changeVisibility(row.id);
                    }}><i className={'bi bi-' + (row.visible ? 'eye' : 'eye-slash')}></i></button>
                    <button type="button" className={"btn btn-secondary"} onClick={() => {
                        window.open("/subitem?id=" + row.id, '_blank');
                    }}><i className={'bi bi-info-lg'}></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);
    const [brands, setBrands] = useState(null);
    const [brand, setBrand] = useState(-1);
    const [subcategories, setSubcategories] = useState(null);


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                            المواد
                                            </div>
                                            <div className="col" align="right">
                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    setAddState(true);
                                                }}><i className="bi bi-plus"></i> اضافة</button>
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <div className="mb-3 d-flex">
                                            <div className="w-100 me-3">
                                                <CustomSelect options={brands} handleChange={setBrand} defaultValue={brand}/>
                                            </div>
                                            <button className="btn btn-danger" onClick={ () => setBrand(-1) }><i class="bi bi-trash"></i></button>
                                        </div>
                                        <DataTable body={brand == -1 ? data : data.filter((x) => x.brand === brand)} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NewItem state={addState} changeState={setAddState} refresh={() => {fetchData();}} brands={brands} subcategories={subcategories}/>
                        <DeleteItem state={deleteState} changeState={setDeleteState} refresh={() => {fetchData();}} item={selectedItem}/>
                        {
                            selectedItem && editState ? 
                            (
                                <EditItem state={editState} changeState={setEditState} refresh={() => {fetchData();}} item={selectedItem} brands={brands} />
                            ) : 
                            <></>
                        }
                        {
                            selectedItem && sendItemMessage ? 
                            (
                                <SendItemNotification state={sendItemMessage} changeState={setSendItemMessage} item={selectedItem} />
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )


    async function fetchBrands() {
        setBrands(null);
        const data = {
            route: "fetch_brands",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            const newData = [];
            brands.data.forEach(e => {
                newData.push({value: e.id, label: e.en_name});
            });
            setBrands(newData);
        }
        else {
            setBrands([]);
        }
    }


    async function fetchSubcategories() {
        setSubcategories(null)
        const data = {
            route: "fetch_subcategory_relations",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var brands = await response.json();
        if (brands.status.code === 1) {
            const newData = [];
            brands.data.forEach(e => {
                newData.push({value: e.id, label: e.ar_name});
            });
            setSubcategories(newData);
        }
        else {
            setSubcategories([]);
        }
    }

    async function fetchData() {
        setData(null);
        setSelectedItem(null);

        await fetchBrands();
        await fetchSubcategories();

        const data = {
            route: "fetch_items",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Item = await response.json();
        if (Item.status.code === 1) {
            setData(Item.data);
        }
        else {
            setData([]);
        }
    }

    async function changeVisibility(id) {
        const data = {
            route: "visibility_item",
            id: id
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تغير حالة الظهور");
                    fetchData();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}