import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host } from "../../Config";

export function AddBounce({state, changeState, item}) {

    const [bounce, setBounce] = useState('');

    return (
        <ButtonModal title='Send bounce' state={state} changeState={changeState} btnAction={() => {
            insert(item.id, bounce);
        }}>
            <input type="number" className="form-control form-control-sm mb-3" value={bounce} placeholder="Bounce" onChange={(e) => setBounce(e.target.value)}/>
        </ButtonModal>
    )

    function insert(customer, bounce) {
        const data = {
            route: "send_bounce",
            bounce: bounce,
            customer: customer,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم ارسال ");
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}