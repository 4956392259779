import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';

export function Coupons() {

    const location = useLocation();

    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'العنوان', prop: 'name', isFilterable: true },
        { title: 'الوصف', prop: 'desc', isFilterable: true },
        { title: 'القيمة', prop: 'amount', isFilterable: true },
        { title: 'اكثر من', prop: 'over_than', isFilterable: true },
        { title: 'مستعمل', prop: 'active', isFilterable: true },
        { title: 'تاريخ الانتهاء', prop: 'expires_at', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        
    ];

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                الخصومات
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )


    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    async function fetchData() {
        setData(null);

        var id = getId();

        const data = {
            route: "fetch_coupons",
            customer: id,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var customers = await response.json();
        if (customers.status.code === 1) {
            setData(customers.data);
        }
        else {
            setData([]);
        }
    }
}