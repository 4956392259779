import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

export function ButtonModal({ title = "Dialog", btnText = "نعم", btnAction = () => {}, state, changeState, children }) {
    var toggle = () => changeState(!state);

    return (
        <>
            <Modal show={state} centered>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-sm" onClick={toggle}>الغاء</button>
                    <button className="btn btn-sm btn-dark" onClick={() => {
                        btnAction();
                        toggle();
                    }}>{btnText}</button>
                </ModalFooter>
            </Modal>
        </>
    );
}