import ReactApexChart from "react-apexcharts";

export const OrdersChart = ({data = [0,0,0,0,0]}) => {
    const info = {
        
        options: {
            chart: {
              id: "basic-bar",
              animations: {
                speed: 200
              }
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                distributed: true
              }
            },
            xaxis: {
              categories: ['المعلقات', 'الماكدات', 'تحت التوصيل', 'التامة', 'الملغيات']
            }
        },
        series: [
          {
            name: "الطلبات",
            data: data
          }
        ]
    }

    return (
        <div className="">
            <div className="card">
                    <div className='card-header'>
                        <div className="row align-items-center">
                            <div className="col fw-bold">
                            جميع الطلبات
                            </div>
                        </div>
                    </div>
                <div className='card-body'>
                    <div className="">
                        <ReactApexChart
                            options={info.options}
                            series={info.series}
                            type="bar"
                            height={300}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}