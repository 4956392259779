import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { CustomSelect } from "../../components/select/CustomSelect";
import { Host } from "../../Config";

export function NewItem({state, changeState, refresh, brands, subcategories}) {

    const [arabic, setArabic] = useState('');
    const [kurdish, setKurdish] = useState('');
    const [english, setEnglish] = useState('');

    const [arabic_desc, setArabicDesc] = useState('');
    const [kurdish_desc, setKurdishDesc] = useState('');
    const [english_desc, setEnglishDesc] = useState('');
    
    const [brand, setBrand] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [price1, setPrice1] = useState(0);
    const [price2, setPrice2] = useState(0);
    const [price3, setPrice3] = useState(0);
    const [limit, setLimit] = useState(1);

    return (
        <ButtonModal title='اضافة مادة جديدة' state={state} changeState={changeState} btnAction={() => {
            insert(arabic, kurdish, english, price1, price2, price3, limit, brand, arabic_desc, kurdish_desc, english_desc, subcategory);
        }}>
            <div className="mb-3">
                <CustomSelect options={brands} handleChange={setBrand} defaultValue={brand}/>
            </div>
            <div className="mb-3">
                <CustomSelect options={subcategories} handleChange={setSubcategory} defaultValue={subcategory}/>
            </div>
            
            <input type="text" className="form-control form-control-sm mb-3" placeholder="عربي" onChange={(e) => setArabic(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="كردي" onChange={(e) => setKurdish(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="انكليزي" onChange={(e) => setEnglish(e.target.value)}/>


            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف العربي" onChange={(e) => setArabicDesc(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف الكردي" onChange={(e) => setKurdishDesc(e.target.value)}/>
            <input type="text" className="form-control form-control-sm mb-3" placeholder="الوصف الانكليزي" onChange={(e) => setEnglishDesc(e.target.value)}/>


            <input type="number" className="form-control form-control-sm mb-3" placeholder="سعر 1" onChange={(e) => setPrice1(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" placeholder="سعر 2" onChange={(e) => setPrice2(e.target.value)}/>
            <input type="number" className="form-control form-control-sm mb-3" placeholder="سعر 3" onChange={(e) => setPrice3(e.target.value)}/>
            <input type="number" min={1} value={limit} className="form-control form-control-sm" placeholder="الحد الاقصي" onChange={(e) => setLimit(e.target.value)}/>
        
        </ButtonModal>
    )

    function insert(ar, kr, en, price1, price2, price3, limit, brand, ar_desc, kr_desc, en_desc, subcategory) {
        if (!ar_desc || ar_desc === '') {
            toast.error("ألرجاء كتابة وصف");
            return;
        }
        if (!kr_desc || kr_desc === '') {
            toast.error("ألرجاء كتابة وصف");
            return;
        }
        if (!en_desc || en_desc === '') {
            toast.error("ألرجاء كتابة وصف");
            return;
        }
        if (!ar || ar === '') {
            toast.error("ألرجاء كتابة الاسم");
            return;
        }
        if (!kr || kr === '') {
            toast.error("ألرجاء كتابة الاسم");
            return;
        }
        if (!en || en === '') {
            toast.error("ألرجاء كتابة الاسم");
            return;
        }
        if (!limit || limit === 0) {
            toast.error("ألرجاء كتابة الحد الاقصى");
            return;
        }
        if (!brand || brand === '') {
            toast.error("ألرجاء أختيار البراند");
            return;
        }
        if (!subcategory || subcategory === '') {
            toast.error("ألرجاء أختيار الصنف الفرعي");
            return;
        }
        const data = {
            route: "new_item",
            ar_desc: ar_desc,
            kr_desc: kr_desc,
            en_desc: en_desc,
            subcategory: subcategory,
            arabic: ar,
            kurdish: kr,
            english: en,
            price1: price1,
            price2: price2,
            price3: price3,
            limit: limit,
            brand: brand
        }
        console.log( JSON.stringify(data));
        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم اضافة المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}