import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { DataTable } from "../../components/datatable/Datatable";
import { Host } from '../../Config';
import { DeleteRelation } from "./DeleteRelation";
import { EditRelation } from "./EditRelation";

export function InsertRelations() {
    const location = useLocation();

    const [addState, setAddState] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'Priority', prop: 'priority', isFilterable: true },
        { title: 'Barcode', prop: 'barcode', isFilterable: true },
        { title: 'Created by', prop: 'created_by', isFilterable: true },
        { title: 'Created at', prop: 'created_at', isFilterable: true },
        {
            title: 'Actions',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setDeleteState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-trash3"></i></button>
                    <button type="button" className="btn btn-dark" onClick={() => {
                        setEditState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-pencil"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);
    const [priority, setPriority] = useState(0);
    const [barcode, setBarcode] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card mb-3">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                Relations
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="col">
                                            Barcode
                                            <input type="text" className="form-control form-control-sm" value={barcode} placeholder="Barcode" onChange={(e) => setBarcode(e.target.value)}/>
                                        </div>
                                        <div className="col">
                                            Priority
                                            <input type="number" className="form-control form-control-sm mb-3" value={priority} placeholder="priority" onChange={(e) => setPriority(e.target.value)}/>
                                        </div>
                                        <div className="row">
                                            <button className="btn btn-dark">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                Relations
                                            </div>
                                            <div className="col" align="right">
                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    setAddState(true);
                                                }}><i className="bi bi-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <DataTable body={data} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DeleteRelation state={deleteState} changeState={setDeleteState} refresh={() => {fetchData();}} item={selectedItem} />
                        {
                            selectedItem && editState ? 
                            (
                                <EditRelation state={editState} changeState={setEditState} refresh={() => {fetchData();}} item={selectedItem} id={getId()}/>
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    function getId() {
        const search = location.search;
        const id = new URLSearchParams(search).get("id");
        return id;
    }

    async function fetchData() {
        setData(null);
        setSelectedItem(null);
        
        const id = getId();

        const data = {
            route: "fetch_relations",
            subcategory: id,
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Relation = await response.json();
        if (Relation.status.code === 1) {
            setData(Relation.data);
        }
        else {
            setData([]);
        }
    }
}