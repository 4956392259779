import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import { DataTable } from "../../components/datatable/Datatable";
import { CustomSelect } from "../../components/select/CustomSelect";
import { Host } from '../../Config';
import { DeleteSubcategory } from "./DeleteSubcategory";
import { EditSubcategory } from "./EditSubcategory";
import { NewSubcategory } from "./NewSubcategory";

export function Subcategories() {
    const [addState, setAddState] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [editState, setEditState] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = [
        { title: '#', prop: 'id', isFilterable: true },
        { title: 'عربي', prop: 'ar_name', isFilterable: true },
        { title: 'كردي', prop: 'kr_name', isFilterable: true },
        { title: 'انكليزي', prop: 'en_name', isFilterable: true },
        { title: 'الصنف', prop: 'category_name', isFilterable: true },
        { title: 'المستخدم', prop: 'created_by', isFilterable: true },
        { title: 'تاريخ الانشاء', prop: 'created_at', isFilterable: true },
        {
            title: 'اجراءات',
            prop: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    {/*
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setDeleteState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-trash3"></i></button>
                     */}
                    <button type="button" className="btn btn-dark" onClick={() => {
                        setEditState(true);
                        setSelectedItem(row)
                    }}><i className="bi bi-pencil"></i></button>
                </div>
            )
        }
    ];

    const [data, setData] = useState(null);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState(-1);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                data ? (
                    <>
                        <div className="pt-3 pe-3 ps-3 pb-3">
                            <div className="card">
                                    <div className='card-header'>
                                        <div className="row align-items-center">
                                            <div className="col fw-bold">
                                                Subcategories
                                            </div>
                                            <div className="col" align="right">
                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    setAddState(true);
                                                }}><i className="bi bi-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                <div className='card-body'>
                                    <div className="">
                                        <div className="mb-3 d-flex">
                                            <div className="w-100 me-3">
                                                <CustomSelect options={categories} handleChange={setCategory} defaultValue={category}/>
                                            </div>
                                            <button className="btn btn-danger" onClick={ () => setCategory(-1) }><i class="bi bi-trash"></i></button>
                                        </div>
                                        <DataTable body={category == -1 ? data : data.filter((x) => x.category === category)} headers={headers} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NewSubcategory state={addState} changeState={setAddState} refresh={() => {fetchData();}} categories={categories} />
                        <DeleteSubcategory state={deleteState} changeState={setDeleteState} refresh={() => {fetchData();}} item={selectedItem} />
                        {
                            selectedItem && editState ? 
                            (
                                <EditSubcategory state={editState} changeState={setEditState} refresh={() => {fetchData();}} item={selectedItem} categories={categories} />
                            ) : 
                            <></>
                        }
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center" id="spinner">
                        <Spinner />
                    </div>
                )
            }
        
            <Toaster position="top-center" />
        </>
    )

    async function fetchCategories() {
        const data = {
            route: "fetch_categories",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var categories = await response.json();
        if (categories.status.code === 1) {
            const newData = [];
            categories.data.forEach(e => {
                newData.push({value: e.id, label: e.en_name});
            });
            setCategories(newData);
        }
        else {
            setCategories([]);
        }
    }

    async function fetchData() {
        await fetchCategories();

        setData(null);
        setSelectedItem(null);

        const data = {
            route: "fetch_subcategories",
        }

        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        var response = await fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        });

        var Subcategory = await response.json();
        if (Subcategory.status.code === 1) {
            setData(Subcategory.data);
        }
        else {
            setData([]);
        }
    }
}