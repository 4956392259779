import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImageUploader } from "../../components/imageuploader/ImageUploader";
import { ButtonModal } from "../../components/modals/ButtonModal";
import { Host, ImageHost } from "../../Config";
import { CustomSelect } from "../../components/select/CustomSelect";

export function EditSubItem({state, changeState, refresh, subitem, tags, setTags}) {

    const [barcode, setBarcode] = useState(subitem.barcode);
    const [color, setColor] = useState(subitem.color);
    const [classValue, setClass] = useState(subitem.class);
    const [discount1, setDiscount1] = useState(subitem.discount1);
    const [discount2, setDiscount2] = useState(subitem.discount2);
    const [discount3, setDiscount3] = useState(subitem.discount3);
    const [point1, setPoint1] = useState(subitem.point1);
    const [point2, setPoint2] = useState(subitem.point2);
    const [point3, setPoint3] = useState(subitem.point3);

    const [image, setImage] = useState();
    const [tag, setTag] = useState();

    const [images, setImages] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [satItems, setSatItems] = useState(false);

    useEffect(() => {
        if (!satItems) {
            subitem.images.forEach(image => {
                images.push({
                    id: image.id,
                    online: true,
                    hover: false,
                    priority: image.priority,
                    data: ImageHost + image.image,
                })
                setImages(images);
            });

            subitem.tags.forEach(tag => {
                console.log(tag);
                selectedTags.push({
                    online: true,
                    id: tag.id,
                    label: tag.en_name,
                    uniqe: Math.random(),
                    hover: false,
                })
                setSelectedTags(selectedTags);
            })

            setSatItems(true);
        }
    }, [images]);

    useEffect(() => {
        if (image) {
            images.push({
                hover: false,
                data: image,
                priority: 0,
            })
            setImages(images)
            setImage(null)
        }
    }, [image, setImage]);

    return (
        <ButtonModal title='تعديل المادة' btnText="تعديل" state={state} changeState={changeState} btnAction={() => {
            insert(subitem.id, barcode, color, classValue, discount1, discount2, discount3, point1, point2, point3, selectedTags, images);
        }}>
            <div className="mb-3">
                <ImageUploader image={image} setImage={setImage}/>
            </div>

            <div className="d-flex flex-wrap mb-3">
                {
                    images.map((e) => {
                        return e && (!e.delete) ? 
                        (
                            <div className="">
                                <div className="d-flex me-2" style={{position: "relative"}} onMouseEnter={() => {
                                const i = images.findIndex((x) => x == e);
                                images[i].hover = true;
                                
                                var imgs = []
                                images.forEach(img => {
                                    imgs.push(img)
                                });

                                setImages(imgs);
                            }} onMouseLeave={() => {
                                const i = images.findIndex((x) => x == e);
                                images[i].hover = false;
                                
                                var imgs = []
                                images.forEach(img => {
                                    imgs.push(img)
                                });

                                setImages(imgs);
                            }}>
                                {
                                    e.hover ? <></> : 
                                    <img src={e.data} style={{width:"50px", height: "50px", borderRadius: "7px"}}></img>
                                        
                                }
                                {
                                    e.hover ? 
                                    <button className="btn btn-danger" onClick={() => {
                                        const i = images.findIndex((x) => x == e);
                                        images[i].hover = false;
                                        
                                        var imgs = []
                                        images.forEach(img => {
                                            if (e.online) {
                                                e.delete = true;
                                                imgs.push(img)
                                            }
                                            else {
                                                if (img !== e)
                                                {
                                                    imgs.push(img)
                                                }
                                            }
                                        });
        
                                        setImages(imgs);
                                    }} style={{width:"50px", height: "50px"}}><i className="bi bi-trash"></i></button> :
                                    <></>
                                }
                                </div>
                                <input type="number" className="form-control form-control-sm ms-2 mt-2" style={{"width": "50px"}} min="0" value={e.priority} onChange={(f) => { 
                                    const i = images.findIndex((x) => x == e);
                                    images[i].priority = f.target.value;
                                    
                                    var imgs = []
                                    images.forEach(img => {
                                        imgs.push(img)
                                    });
    
                                    setImages(imgs);
                                }}/>

                            </div>
                        ) : <></>
                    })
                }
            </div>

            <div className="mb-3 d-flex">
                <div className="w-100 me-3">
                    <CustomSelect options={tags} handleChange={setTag} defaultValue={tag}/>
                </div>
                <button className="btn btn-dark btn-sm" onClick={() => {
                    if (tag) {
                        const i = tags.findIndex((x) => x.value == tag);
                        selectedTags.push({
                            id: tags[i].value,
                            label: tags[i].label,
                            uniqe: Math.random(),
                            hover: false,
                        });
                        setSelectedTags(selectedTags);
                        var tags_ = []
                        tags.forEach(t => {
                            tags_.push(t)
                        });

                        setTags(tags_);
                    }
                }}>اضافة</button>
            </div>

            <div className="d-flex flex-wrap">
                {
                    selectedTags.map((tag) => {
                        return tag && (!tag.delete) ? (
                            <div className="me-2 mb-2" onMouseEnter={() => {
                                const i = selectedTags.findIndex((x) => x == tag);
                                selectedTags[i].hover = true;
                                
                                var tags = []
                                selectedTags.forEach(tag => {
                                    tags.push(tag)
                                });
                                setSelectedTags(tags);
                            }} onMouseLeave={() => {
                                const i = selectedTags.findIndex((x) => x == tag);
                                selectedTags[i].hover = false;
                                
                                var tags = []
                                selectedTags.forEach(tag => {
                                    tags.push(tag)
                                });
                                setSelectedTags(tags);
                            }}>
                                <button type="button" className="btn btn-sm btn-secondary" onClick={() => {
                                    const i = selectedTags.findIndex((x) => x == tag);
                                    selectedTags[i].hover = false;

                                    var tags_ = []
                                    selectedTags.forEach(t => {
                                        if (tag.online) {
                                            tag.delete = true;
                                            tags_.push(t)
                                        }
                                        else {
                                            if (t !== tag)
                                            {
                                                tags_.push(t)
                                            }
                                        }
                                    });

                                    setSelectedTags(tags_);
                                }}>
                                    {tag.label}
                                    {
                                        tag.hover ? <span className="badge badge-light"><i className="bi bi-x"></i></span> : <></>
                                    }
                                </button>
                            </div>
                        ) : <></>
                    })
                }
            </div>

            <input type="text" className="form-control form-control-sm mb-3" value={barcode} placeholder="الباركود" onChange={(e) => setBarcode(e.target.value)}/>

            
            <div className="d-flex mb-3">
                <input type="text" className="form-control form-control-sm me-3" value={color} placeholder="اللون" onChange={(e) => setColor(e.target.value)}/>
                <input type="text" className="form-control form-control-sm" value={classValue} placeholder="الكلاس" onChange={(e) => setClass(e.target.value)}/>
            </div>

            <div className="d-flex mb-3">
                <input type="number" className="form-control form-control-sm me-3" value={discount1} placeholder="1 الخصم" onChange={(e) => setDiscount1(e.target.value)}/>
                <input type="number" className="form-control form-control-sm me-3" value={discount2} placeholder="2 الخصم" onChange={(e) => setDiscount2(e.target.value)}/>
                <input type="number" className="form-control form-control-sm" value={discount3} placeholder="3 الخصم" onChange={(e) => setDiscount3(e.target.value)}/>
            </div>
            <div className="d-flex mb-3">
                <input type="number" className="form-control form-control-sm me-3" value={point1} placeholder="1 بونص" onChange={(e) => setPoint1(e.target.value)}/>
                <input type="number" className="form-control form-control-sm me-3" value={point2} placeholder="2 بونص" onChange={(e) => setPoint2(e.target.value)}/>
                <input type="number" className="form-control form-control-sm" value={point3} placeholder="3 بونص" onChange={(e) => setPoint3(e.target.value)}/>
            </div>
        </ButtonModal>
    )

    function insert(id, barcode, color, classValue, discount1, discount2, discount3, point1, point2, point3, tags, images) {
        const data = {
            route: "edit_sub_item",
            color: color,
            barcode: barcode,
            class: classValue,
            discount1: discount1,
            discount2: discount2,
            discount3: discount3,
            point1: point1,
            point2: point2,
            point3: point3,
            images: images,
            tags: tags,
            id: id,
        }
        console.log( JSON.stringify(data));
        if (!localStorage || !localStorage.getItem('token')) {
            return;
        }
        const token = localStorage.getItem('token');

        fetch(Host, {
            body: JSON.stringify(data),
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            mode: 'cors',
        }).then((response) => {
            response.json().then((json) => {
                if (json.status.code === 1) {
                    toast.success("تم تعديل المادة");
                    refresh();
                }
                else {
                    toast.error("حذث خطاء ما");
                }
            })
        }).catch(() => {
            toast.error("حذث خطاء ما");
        })
    }
}