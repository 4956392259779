import './App.css';

import React, { useState } from 'react';
import { Sidebar } from './components/sidebar/Sidebar';
import { Login } from './pages/login/Login';
import { useLocation } from 'react-router-dom';
import Nothing from './Nothing';

function App() {

  const locations = useLocation();
  const [wait, setWait] = useState(false);
  useState(() => {
    
    if (locations.pathname === "/product_item") {
      const search = locations.search;
      const id = new URLSearchParams(search).get("id");
      const color = new URLSearchParams(search).get("color");
      window.location.href = `baghekhatina://${id}/${color}`;
    }
    else {
      if (window.location.host.includes('dashboard')){
        setWait(true);
      }
    }
  }, []);

  if (localStorage && localStorage.getItem('token') != null) {
    return wait === true ? (
      <Sidebar />
    ) : <Nothing />;
  } return wait === true ? (
    <Login />
  ) : <Nothing />;
}

export default App;
