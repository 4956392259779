import React from 'react'
import Select from 'react-select'

export const CustomSelect = ({options, handleChange, defaultValue}) => {

  let obj = null;
  try {
    obj = options.find(o => o.value == defaultValue);
  }
  catch {}
  return (
    <Select options={options} onChange={(v) => handleChange(v.value)} defaultValue={obj} />
  )
}